import { logEvent } from "firebase/analytics";
import {
  User,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { analytics } from "../firebase/Analytics";
import app from "./Config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export type AuthUser = User | undefined;

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// ポップアップを表示して、Googleでサインインする関数
export const signInGoogle = async (): Promise<boolean> => {
  return await signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error("credential is null");
      }
      return true;
    }).catch((e) => {
      logEvent(analytics, `${e.message}`, {
        app: "maitake-home",
        module: "firebase/Authentication-Google.tsx",
        function: "signInGoogle"
      });

      return false;
    });
}

// サインアウトする関数
export const signOutGoogle = async (): Promise<boolean> => {
  return await signOut(auth).then(() => {
    return true;
  }).catch((e) => {
    logEvent(analytics, `${e.message}`, {
      app: "maitake-home",
      module: "firebase/Authentication-Google.tsx",
      function: "signOutGoogle"
    });
    return false;
  });
}

// Googleでサインインしているか確認する関数
export const onAuthStateChangedGoogle = (): Promise<boolean> => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

// 現在のサインインしているアカウントを確認する関数
export const getCurrentUser = (): AuthUser => {
  const user = auth.currentUser;
  if (!user) {
    return undefined;
  }
  return user;
}
