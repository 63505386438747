import {
  Typography, Link
} from "@mui/material";
import {
  Home as HomeIcon,
  Help as HelpIcon,
  Apps as AppsIcon,
  LockOpen as LockOpenIcon,
  Construction as ConstructionIcon,
  SportsEsports as SportsEsportsIcon,
  SmartToy as SmartToyIcon,
  Verified as VerifiedIcon,
  CatchingPokemon as CatchingPokemonIcon,
  DinnerDining as DinnerDiningIcon,
  Balance as BalanceIcon,
  QuestionAnswer as QuestionAnswerIcon,
  RecordVoiceOver as RecordVoiceOverIcon,
  Lan as LanIcon
} from "@mui/icons-material";
import {
  green,
  deepPurple,
  cyan,
  purple,
  indigo,
  red,
  blueGrey
} from "@mui/material/colors";
import SignOut from "../components/SignOut";
import UpdateNickname from "../components/UpdateNickname";

// アプリのカテゴリタグ
export const tagList = [
  {
    label: "すべて",
    icon: <AppsIcon />
  },
  {
    label: "サインイン不要",
    icon: <LockOpenIcon />
  },
  {
    label: "ユーティリティ",
    icon: <ConstructionIcon />
  },
  {
    label: "ゲーム",
    icon: <SportsEsportsIcon />
  },
  {
    label: "AI",
    icon: <SmartToyIcon />
  },
  {
    label: "オリジナル",
    icon: <VerifiedIcon />
  },
  {
    label: "ポケモン",
    icon: <CatchingPokemonIcon />
  }
];
// アプリ一覧
export const appList = [
  {
    title: "スマートレシピ検索",
    icon: <DinnerDiningIcon />,
    color: green,
    url: "https://maitake-recipe.pages.dev",
    public: false,
    tags: [2, 4, 5]
  },
  {
    title: "生成AI知恵比べ",
    icon: <BalanceIcon />,
    color: deepPurple,
    url: "https://maitake-gai.pages.dev/",
    public: false,
    tags: [2, 4, 5]
  },
  {
    title: "まいたけAI",
    icon: <SmartToyIcon />,
    color: cyan,
    url: "https://maitake-ai.pages.dev",
    public: false,
    tags: [2, 4, 5]
  },
  {
    title: "まいたけAIチャット",
    icon: <QuestionAnswerIcon />,
    color: purple,
    url: "https://maitake-chat.pages.dev/",
    public: true,
    tags: [2, 4, 5]
  },
  {
    title: "音声文字起こし",
    icon: <RecordVoiceOverIcon />,
    color: indigo,
    url: "https://maitaketeikoku.github.io/maitake-speech/",
    public: true,
    tags: [2, 4, 5]
  },
  {
    title: "ポケモン図鑑",
    icon: <CatchingPokemonIcon />,
    color: red,
    url: "https://maitaketeikoku.github.io/maitake-pokedex/",
    public: true,
    tags: [2, 6]
  },
  {
    title: "IPアドレス",
    icon: <LanIcon />,
    color: blueGrey,
    url: "https://ip-access.pages.dev/",
    public: true,
    tags: [2, 5]
  }
];

const listStyle = {
  paddingLeft: 16
};
// ヘルプに表示する内容
export const helpList = [
  {
    title: "基本操作",
    contents: [
      {
        summary: "ご意見・お問い合わせ",
        details: (
          <div>
            <Link href="https://docs.google.com/forms/d/e/1FAIpQLSeX2wsW_hxwVU0YSJNVFWu3MYZcp_5thSwl55oZvvP9atgYvA/viewform?usp=sf_link"  target="_blank">
              {"お問い合わせフォーム"}
            </Link>
            <Typography>
              {"バグの報告からご意見・ご要望など、何でも教えてね！"}
            </Typography>
          </div>
        )
      },
      {
        summary: "サインアウト",
        details: (
          <div>
            <SignOut />
          </div>
        )
      },
      {
        summary: "ニックネームの変更",
        details: (
          <div>
            <UpdateNickname />
          </div>
        )
      }
    ]
  },
  {
    title: "FAQ",
    contents: [
      {
        summary: "退会方法は？",
        details: (
          <div>
            <Typography>
              {"お問い合わせフォームで教えてね！"}
            </Typography>
          </div>
        )
      }
    ]
  },
  {
    title: "その他",
    contents: [
      {
        summary: "プライバシーポリシー",
        details: (
          <div>
            <Typography variant="caption">
              <ol style={listStyle}><li>
                {"Google Analyticsについて"}
                <ul style={listStyle}><li>
                  {"当サイトではGoogle Analyticsを利用しています。"}
                </li><li>
                    {"トラフィックデータの収集のためにCookieを使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。"}
                  </li></ul>
              </li></ol>
            </Typography>
          </div>
        )
      },
      {
        summary: "利用規約",
        details: (
          <div>
            <Typography variant="caption">
              <ol style={listStyle}><li>
                {"リンクについて"}
                <ul style={listStyle}><li>
                  {"当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。"}
                </li><li>
                    {"ただし、インラインフレームの使用や画像の直リンクは禁止します。"}
                  </li></ul>
              </li><li>
                  {"免責事項について"}
                  <ul style={listStyle}><li>
                    {"当サイトの情報や、当サイトのリンクやバナーなどで移動したサイトで提供される情報やサービス等について、損害等の一切の責任を負いません。"}
                  </li><li>
                      {"できる限り正確な情報を提供するように努めていますが、正確性や安全性を保証するものではありません。"}
                    </li></ul>
                </li><li>
                  {"著作権について"}
                  <ul style={listStyle}><li>
                    {"当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。"}
                  </li><li>
                      {"当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がある場合、お問い合わせフォームよりご連絡ください。"}
                    </li></ul>
                </li></ol>
            </Typography>
          </div>
        )
      }
    ]
  }
];

// ウェルカム画面のサインインのステップ
export const stepList = [
  {
    label: "Googleでサインイン"
  },
  {
    label: "ニックネームを登録"
  }
];

// メイン画面のナビゲーション
export const navigationList = [
  { label: "ホーム", icon: <HomeIcon /> },
  { label: "ヘルプ", icon: <HelpIcon /> }
];
