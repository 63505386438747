import { useMemo } from "react";
import {
  Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Grid,
  Typography, IconButton
} from "@mui/material";
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import { useStateContext } from "../utils/StateContext";
import SignOut from "./SignOut";

interface UserDialogProps {
  userDialogOpen: boolean;
  setUserDialogOpen: (userDialogOpen: boolean) => void;
}

function UserDialog({
  userDialogOpen,
  setUserDialogOpen,
}: UserDialogProps): JSX.Element {
  const {
    nickname,
    createdDate,
    updatedDate
  } = useStateContext();

  // 表示する登録日時
  const createdDateDisplay: string = useMemo(() => {
    if (createdDate === "") {
      return "";
    }
    return new Date(createdDate).toLocaleString("ja-JP");
  }, [createdDate]);

  // 表示する更新日時
  const updatedDateDisplay: string = useMemo(() => {
    if (updatedDate === "") {
      return "";
    }
    return new Date(updatedDate).toLocaleString("ja-JP");
  }, [updatedDate]);

  return (
    <Dialog
      open={userDialogOpen}
      onClose={() => setUserDialogOpen(false)}
    >
      <DialogTitle>
        {`${nickname}`}
      </DialogTitle>
      <DialogContent>
        <Grid container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid item xs={12}
            sx={{ mb: 3 }}
          >
            <SignOut />
          </Grid>

          <Grid item xs={4}>
            <Typography>
              {`登録日時`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {createdDateDisplay}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography>
              {`更新日時`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              {updatedDateDisplay}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Tooltip arrow
          title="閉じる"
        >
          <IconButton
            onClick={() => setUserDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;
