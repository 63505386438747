import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/Analytics";

type Url = "createNickname" | "readNickname" | "updateNickname";
type Data = {
  result: boolean;
  message: string;
  nickname: string;
  createdDate: string;
  updatedDate: string;
} | undefined;

export const fetchPost = async (url: Url, uid: string, nickname: string): Promise<Data> => {
  try {
    const response = await fetch(`/api/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: uid,
        nickname: nickname
      })
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-home",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-home",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-home",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
    }
    return undefined;
  }
}
