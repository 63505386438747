import { useState } from "react";
import {
  Container,
  Typography, Divider
} from "@mui/material";
import { useStateContext } from "../utils/StateContext";
import AppList from "../components/AppList";

function Home(): JSX.Element {
  const {
    nickname
  } = useStateContext();

  const [selectTag, setSelectTag] = useState<number>(0);

  return (
    <div>
      <Container maxWidth="xs">
        <Typography variant="h6"
          sx={{ mt: 2 }}
        >
          {`ようこそ！ ${nickname} さん`}
        </Typography>

        <Divider sx={{ mt: 1 }} />
      </Container>

      <AppList
        isPublic={false}
        selectTag={selectTag}
        setSelectTag={setSelectTag}
      />
    </div>
  );
}

export default Home;
