import { useState } from "react";
import {
  Container, Grid,
  Button, Divider
} from "@mui/material";
import AppList from "../components/AppList";

interface GuestProps {
  setIsGuest: (isGuest: boolean) => void;
}
function Guest({
  setIsGuest
}: GuestProps): JSX.Element {

  const [selectTag, setSelectTag] = useState<number>(0);

  return (
    <div>
      <Container maxWidth="xs">
        <Grid container
          spacing={2}
          sx={{
            mt: 2,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <Grid item xs={12}>
            <Button
              onClick={() => setIsGuest(false)}
              variant="outlined"
              color="inherit"
              size="small"
            >
              {`サインインして、すべてのアプリを利用する`}
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2 }} />
      </Container>

      <AppList
        isPublic={true}
        selectTag={selectTag}
        setSelectTag={setSelectTag}
      />
    </div>
  );
}

export default Guest;
