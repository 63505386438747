import { cloneElement } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Container, Grid, Stack,
  Chip,
  Button, Typography
} from "@mui/material";
import { tagList, appList } from "../utils/Config";

interface AppListProps {
  isPublic: boolean;
  selectTag: number;
  setSelectTag: (selectTag: number) => void;
}

function AppList({
  isPublic,
  selectTag,
  setSelectTag
}: AppListProps): JSX.Element {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <Container maxWidth="md">
      <Grid container
        spacing={2}
      >
        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            flexWrap="wrap"
          >
            {tagList.map((tag, index) => (
              <Chip
                key={index}
                label={tag.label}
                icon={tag.icon}
                onClick={() => setSelectTag(index)}
                variant={
                  selectTag === index
                    ? "filled"
                    : "outlined"
                }
              />
            ))}
          </Stack>
        </Grid>

        {appList.map((app, index) => (
          (app.tags.includes(selectTag) || selectTag === 0 || (selectTag === 1 && app.public)) &&
          <Grid item
            key={index}
            xs={6} sm={4} md={3}
            sx={{
              mt: 2,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button
              disabled={isPublic && !app.public}
              href={app.url}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: prefersDarkMode
                  ? app.color[200]
                  : app.color[400]
              }}
            >
              {cloneElement(app.icon, { sx: { fontSize: 64 } })}
              <Typography
                color={
                  (isPublic && !app.public)
                    ? "inherit"
                    : prefersDarkMode
                      ? "white"
                      : "black"
                }
                sx={{ textTransform: "none" }}
              >
                {app.title}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AppList;
