import { useState } from "react";
import {
  Container,
  Typography, Divider,
  Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon
} from "@mui/icons-material";
import { helpList } from "../utils/Config";

function Help(): JSX.Element {
  // 展開する項目
  const [expandAccordion, setExpandAccordion] = useState<string>("");

  const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandAccordion(isExpanded ? panel : "");
  };

  return (
    <Container maxWidth="xs">
      {helpList.map((help, helpIndex) => (
        <div key={helpIndex}>
          <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
            {help.title}
          </Typography>
          {help.contents.map((content, contentIndex) => (
            <Accordion
              key={contentIndex}
              expanded={expandAccordion === `${helpIndex}-${contentIndex}`}
              onChange={handleAccordion(`${helpIndex}-${contentIndex}`)}
              sx={{ textAlign: "start" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                {content.summary}
              </AccordionSummary>
              <AccordionDetails>
                {content.details}
              </AccordionDetails>
            </Accordion>
          ))}
          <Divider sx={{ mt: 2 }} />
        </div>
      ))}
    </Container>
  );
}

export default Help;
