import { useState, useEffect, useMemo } from "react";
import {
  Container, Grid,
  Stepper, Step, StepLabel,
} from "@mui/material";
import {
  onAuthStateChangedGoogle,
  getCurrentUser
} from "../firebase/Authentication-Google";
import { fetchPost } from "../utils/FetchFunctions";
import { useStateContext } from "../utils/StateContext";
import { stepList } from "../utils/Config";
import SignIn from "./SignIn";
import CreateNickname from "./CreateNickname";
import Guest from "./Guest";

function Welcome(): JSX.Element {
  const {
    createMessage,
    setIsRunning,
    isSignIn,
    setIsSignIn,
    nickname,
    setNickname,
    setCreatedDate,
    setUpdatedDate
  } = useStateContext();

  // ゲストユーザかどうか
  const [isGuest, setIsGuest] = useState<boolean>(false);

  // ウェルカム画面が読み込まれた時にサインインしているか確認
  useEffect(() => {
    (async () => {
      setIsRunning(true);
      createMessage("読み込み中...", "info");

      // Googleアカウントでサインインしているか確認
      const authResult = await onAuthStateChangedGoogle();
      setIsSignIn(authResult);

      if (!authResult) {
        createMessage("サインインしていないよ。", "warning");
        setIsRunning(false);
        return;
      }

      createMessage("サインインしているよ！", "success");
      setIsRunning(false);

      await readNickname();
    })();
    // eslint-disable-next-line
  }, []);

  // ウェルカム画面のサインインのステップ
  const activeStep = useMemo(() => {
    if (!isSignIn) {
      return 0;
    } else if (nickname === "") {
      return 1;
    }
  }, [isSignIn, nickname]);

  const readNickname = async (): Promise<void> => {
    setIsRunning(true);
    createMessage("ニックネーム取得中...", "info");

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "warning");
      setIsRunning(false);
      return;
    }

    const uid = currentUser.uid;
    const data = await fetchPost("readNickname", uid, nickname);

    if (data === undefined) {
      setNickname("");
      setCreatedDate("");
      setUpdatedDate("");
      createMessage("ニックネーム取得失敗。", "error");
      setIsRunning(false);
      return;
    }

    if (data.result) {
      setNickname(data.nickname);
      setCreatedDate(data.createdDate);
      setUpdatedDate(data.updatedDate);
    }
    createMessage(data.message, (data.result ? "success" : "warning"));
    setIsRunning(false);
  }

  return (
    <div>
      {isGuest
        ?
        <Guest
          setIsGuest={setIsGuest}
        />
        :
        <Container maxWidth="xs">
          <Grid container
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <Grid item container xs={12}>
              <Grid item xs={12}
                sx={{ mt: 4 }}
              >
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {stepList.map((step: any) => (
                    <Step key={step.label}>
                      <StepLabel>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              {(activeStep === 0) &&
                <Grid item xs={12}>
                  <SignIn
                    readNickname={readNickname}
                    setIsGuest={setIsGuest}
                  />
                </Grid>
              }

              {(activeStep === 1) &&
                <Grid item xs={12}>
                  <CreateNickname
                    readNickname={readNickname}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </Container>
      }
    </div>
  );
}

export default Welcome;
