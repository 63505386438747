import { useState, useEffect } from "react";
import { setUserProperties } from "firebase/analytics";
import {
  Box
} from "@mui/material";
import { analytics } from "../firebase/Analytics";
import { useStateContext } from "../utils/StateContext";
import Welcome from "./Welcome";
import MainAppBar from "../components/MainAppBar";
import Home from "./Home";
import Help from "./Help";
import Navigation from "../components/Navigation";
import UserDialog from "../components/UserDialog";

function Main(): JSX.Element {
  const {
    isSignIn,
    nickname
  } = useStateContext();

  // ユーザダイアログを開くか
  const [userDialogOpen, setUserDialogOpen] = useState<boolean>(false);
  // ナビゲーションのどれを開くか
  const [navigation, setNavigation] = useState<number>(0);

  useEffect(() => {
    if (!isSignIn) {
      setUserDialogOpen(false);
      setNavigation(0);
      setUserProperties(analytics, { signIn: false });
    } else {
      setUserProperties(analytics, { signIn: true });
    }
  }, [isSignIn]);

  return (
    <div>
      {(!isSignIn || nickname === "")
        ?
        <Welcome />
        :
        <div>
          <MainAppBar
            setUserDialogOpen={setUserDialogOpen}
          />

          <UserDialog
            userDialogOpen={userDialogOpen}
            setUserDialogOpen={setUserDialogOpen}
          />

          {navigation === 0 &&
            <Home />
          }

          {navigation === 1 &&
            <Help />
          }

          <Box sx={{ mb: 8 }} />

          <Navigation
            navigation={navigation}
            setNavigation={setNavigation}
          />
        </div>
      }
    </div>
  );
}

export default Main;
