import {
  Grid,
  TextField, InputAdornment,
  Button
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Send as SendIcon
} from "@mui/icons-material";
import { checkRegExp } from "../utils/Utils";
import { useStateContext } from "../utils/StateContext";

interface NicknameFormProps {
  newNickname: string;
  setNewNickname: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  onClick: () => void;
  title: string;
}

function NicknameForm({
  disabled,
  newNickname,
  setNewNickname,
  onClick,
  title
}: NicknameFormProps): JSX.Element {
  const {
    isRunning,
    isSignIn
  } = useStateContext();

  return (
    <div>
      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={10}
          sx={{ mt: 2 }}
        >
          <TextField
            label="ニックネーム"
            value={newNickname}
            onChange={(e) => setNewNickname(e.target.value)}
            required
            disabled={isRunning
              || (!isSignIn)
              || disabled
            }
            error={!checkRegExp(newNickname, /^[a-zA-Z0-9]{1,12}$/) && newNickname !== ""}
            helperText={"半角英数字1~12文字"}
            inputProps={{ maxLength: 12 }}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Button
            onClick={onClick}
            disabled={isRunning
              || (!isSignIn)
              || !checkRegExp(newNickname, /^[a-zA-Z0-9]{1,12}$/)
              || disabled
            }
            startIcon={<SendIcon />}
            variant="outlined"
            size="large"
            color="secondary"
          >
            {`${title}`}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default NicknameForm;
