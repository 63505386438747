import {
  Tooltip,
  AppBar, Toolbar,
  Typography, IconButton, Avatar
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon
} from "@mui/icons-material";

interface MainAppBarProps {
  setUserDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MainAppBar({
  setUserDialogOpen
}: MainAppBarProps): JSX.Element {

  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Tooltip arrow
          title="更新"
        >
          <IconButton
            onClick={() => window.location.reload()}
            sx={{ p: 0, mr: 2 }}
          >
            <Avatar
              alt="Maitake"
              src={`${process.env.PUBLIC_URL}/logo192.png`}
            />
          </IconButton>
        </Tooltip>
        <Typography variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {`まいたけ帝国`}
        </Typography>

        <Tooltip arrow
          title="ユーザ情報"
        >
          <IconButton
            onClick={() => setUserDialogOpen(true)}
            size="large"
            color="inherit"
            edge="end"
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

export default MainAppBar;
