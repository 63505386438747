import { useState } from "react";
import { logEvent } from "firebase/analytics";
import {
  Grid,
  Tooltip,
  Typography,
  IconButton
} from "@mui/material";
import {
  Refresh as RefreshIcon
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { fetchPost } from "../utils/FetchFunctions";
import { useStateContext } from "../utils/StateContext";
import NicknameForm from "../components/NicknameForm";
import SignOut from "../components/SignOut";

interface CreateNicknameProps {
  readNickname: () => Promise<void>;
}

function CreateNickname({
  readNickname
}: CreateNicknameProps): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    nickname,
    setNickname,
    setCreatedDate,
    setUpdatedDate
  } = useStateContext();

  // 登録する新しいニックネーム
  const [newNickname, setNewNickname] = useState<string>("");

  const createNickname = async (): Promise<void> => {
    setIsRunning(true);
    createMessage("ニックネーム登録中...", "info");
    logEvent(analytics, "ニックネームの「登録」ボタン押下", {
      app: "maitake-home",
      module: "pages/CreateNickname.tsx",
      function: "createNickname"
    });

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "error");
      setIsRunning(false);
      return;
    }

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;
    const data = await fetchPost("createNickname", uid, newNickname);

    if (data === undefined) {
      setNickname("");
      setCreatedDate("");
      setUpdatedDate("");
      createMessage("ニックネーム登録失敗。", "error");
      setIsRunning(false);
      return;
    }

    if (data.result) {
      setNickname(data.nickname);
      setCreatedDate(data.createdDate);
      setUpdatedDate(data.updatedDate);
    }
    createMessage(data.message, (data.result ? "success" : "error"));
    setIsRunning(false);
  }

  const clickRefresh = async (): Promise<void> => {
    logEvent(analytics, "ニックネームの「リフレッシュ」ボタン押下", {
      app: "maitake-home",
      module: "pages/CreateNickname.tsx",
      function: "clickRefresh"
    });

    await readNickname();
  }

  return (
    <div>
      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}
          sx={{ mt: 8 }}
        >
          <Typography>
            {`ニックネームを登録してね！`}
          </Typography>
          <Typography>
            {`登録したニックネームは`}
          </Typography>
          <Typography>
            {`他のユーザに公開されるよ！`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <NicknameForm
            disabled={nickname !== ""}
            newNickname={newNickname}
            setNewNickname={setNewNickname}
            onClick={createNickname}
            title={"登録"}
          />
        </Grid>

        <Grid item xs={6}
          sx={{ mt: 12 }}
        >
          <SignOut />
        </Grid>

        <Grid item xs={3}
          sx={{ mt: 12 }}
        >
          <Tooltip arrow
            title="更新"
          >
            <IconButton
              onClick={clickRefresh}
              disabled={isRunning
                || (!isSignIn)
                || nickname !== ""
              }
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateNickname;
