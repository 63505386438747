import { useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import {
  Grid,
  Typography,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { fetchPost } from "../utils/FetchFunctions";
import { useStateContext } from "../utils/StateContext";
import NicknameForm from "./NicknameForm";

function UpdateNickname(): JSX.Element {
  const {
    createMessage,
    setIsRunning,
    setNickname,
    setCreatedDate,
    updatedDate,
    setUpdatedDate
  } = useStateContext();

  // 更新可能になる経過日数
  const updateDiffDay = Number(process.env.REACT_APP_updateDiffDay);

  // 更新する新しいニックネーム
  const [newNickname, setNewNickname] = useState<string>("");

  // ニックネームを更新可能か
  const canUpdate = useMemo(() => {
    if (updatedDate === "") {
      return false;
    }
    const lastUpadatedAt = new Date(updatedDate);
    const updatedAt = new Date();

    // 更新可能になる経過時間
    const updateDiff = updateDiffDay * 24 * 60 * 60 * 1000;
    // 更新可能になる経過時間を超えているか確認
    if ((updatedAt.getTime() - lastUpadatedAt.getTime()) < updateDiff) {
      return false;
    }
    return true;
  }, [updatedDate, updateDiffDay]);

  // 表示する更新日時
  const updatedDateDisplay: string = useMemo(() => {
    if (updatedDate === "") {
      return "";
    }
    return new Date(updatedDate).toLocaleString("ja-JP");
  }, [updatedDate]);

  const updateNickname = async (): Promise<void> => {
    setIsRunning(true);
    createMessage("ニックネーム更新中...", "info");
    logEvent(analytics, "ニックネームの「更新」ボタン押下", {
      app: "maitake-home",
      module: "components/UpdateNickname.tsx",
      function: "updateNickname"
    });

    // ニックネームを更新可能でない場合
    if (!canUpdate) {
      createMessage(`前回の更新から${updateDiffDay}日以上経過していないよ。`, "error");
      setIsRunning(false);
      return;
    }

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていません。", "error");
      setIsRunning(false);
      return;
    }

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;
    const data = await fetchPost("updateNickname", uid, newNickname);

    if (data === undefined) {
      setNickname("");
      setCreatedDate("");
      setUpdatedDate("");
      createMessage("ニックネーム更新失敗。", "error");
      setIsRunning(false);
      return;
    }

    if (data.result) {
      setNickname(data.nickname);
      setCreatedDate(data.createdDate);
      setUpdatedDate(data.updatedDate);
    }
    createMessage(data.message, (data.result ? "success" : "error"));
    setIsRunning(false);
  }

  return (
    <div>
      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={4}>
          <Typography>
            {`更新日時`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {updatedDateDisplay}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            {`前回の更新から${updateDiffDay}日以上経過している必要があるよ。`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {canUpdate
            ? <CheckCircleIcon color="success" />
            : <CancelIcon color="error" />}
        </Grid>

        <Grid item xs={12}>
          <NicknameForm
            disabled={!canUpdate}
            newNickname={newNickname}
            setNewNickname={setNewNickname}
            onClick={updateNickname}
            title="更新"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdateNickname;
